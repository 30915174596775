// extracted by mini-css-extract-plugin
export var caseContactFormSection = "b0_k5";
export var caseStudyBackground = "b0_nK";
export var caseStudyBackground__lineColor = "b0_kX";
export var caseStudyCta__bgLight = "b0_nL";
export var caseStudyHead = "b0_k7";
export var caseStudyHead__imageWrapper = "b0_kV";
export var caseStudyProjectsSection = "b0_k6";
export var caseStudyQuote__bgRing = "b0_k2";
export var caseStudySolution__ring = "b0_k0";
export var caseStudySolution__ringBottom = "b0_nM";
export var caseStudySolution__ringRight = "b0_nN";
export var caseStudyTechnology__bg = "b0_nP";
export var caseStudy__bgDark = "b0_kT";
export var caseStudy__bgLight = "b0_kS";